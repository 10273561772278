<template>
	<div class="headTips" ref="listRef">
		<div class="refresh-msg">
			<span class="refresh-btn" @click="refreshNotice">
				<a-icon type="sync" style="font-size: 14px; margin-right: 5px;" :spin="refreshing" />
				刷新
			</span>
		</div>
		<a-spin :spinning="refreshing" tip="正在刷新..." :delay="200">
			<div v-show="msgList.length > 0" class="notice-list">
				<div class="notice" v-for="notice in msgList">
					<div
						style="font-family: PingFang SC Medium;font-size: 12px;font-weight: normal;color: rgba(0, 0, 0, 0.45);">
						{{ notice.createdTime }}</div>
					<div style="display: flex;align-items: flex-start;justify-content: center;margin-top: 8px;">
						<img v-if="notice.userPhotoMedia" class="head"
							:src="notice.userPhotoMedia ? `${notice.userPhotoMedia}` : NotImage">
						<DefaultAvatar v-else :name="notice.operatoredName"></DefaultAvatar>
						<div @click="handleMsgClick(notice)" class="tips_box">
							<div class="tips_msg">
                               <span style="color:#1890FF;">{{ notice.operatoredName }} : </span>
                                {{ `${notice.notices}` }}
                            </div>
							<div style="display: flex;align-items: center;margin-left: 10px;padding: 16px 8px;">
                                <a-avatar shape="square" v-if="notice.operatorType == 6" :size="24" style="background: #00a870;margin-right: 8px;">
                                    {{notice.title  }}
                                </a-avatar>
								<img v-else style="width: 24px;" :src="getTypeIcon(notice.operatorType)">
								<div style="font-size: 14px;color: rgba(0, 0, 0, 0.85);margin-left: 12px;">{{
										notice.title
								}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-show="msgList.length === 0" style="height: 450px; display: flex; justify-content: center; align-items: center;color: rgba(0, 0, 0, 0.45);">
				<a-empty style="margin: auto;"  description="暂无消息">
				</a-empty>
			</div>
			<a-spin size="small" :spinning="loading" tip="正在加载中..." :delay="300">
				<div style="height: 70px; background: #ffffff;"></div>
			</a-spin>
			<div v-show="isOverPage && !loading" style="color: rgba(0,0,0,0.45);padding-bottom:16px;">
				已加载全部消息~
			</div>
		</a-spin>
		<a-modal :visible.sync="inviteModalVisible" :width="600" title="项目组邀请" @cancel="inviteModalVisible = false;">
			<div style="text-align: center;">
				<p> <span style="color:#1890FF;">{{ clickedNotice.operatoredName }} </span>
					邀请您进入 <span style="color:#1890FF;">{{ clickedNotice.title }} </span> </p>
			</div>
			<template slot="footer">
				<div style="text-align: center;">
					<a-button @click="handleInviteAndAccept(3)"> 拒绝 </a-button>
					<a-button type="primary" @click="handleInviteAndAccept(2)"> 接受 </a-button>
				</div>
			</template>
		</a-modal>
	</div>
</template>

<script>
import { onClickOutside, useScroll } from '@vueuse/core';
import dayjs from 'dayjs';
import { computed, nextTick, onMounted, ref, watch } from 'vue-demi'
import { getUserNotice } from '../api/others';
import NotImage from "../assets/not.jpg";
import ExperimentIcon from '../assets/notice/experiment.png';
import CageIcon from '../assets/notice/cage.png';
import ProjectIcon from '../assets/notice/project.png';
import CloudIcon from '../assets/yp-notice.png';
import { joinProject } from '../api/projectUserManager';
import { message } from 'ant-design-vue';
import router from '../router';
import DefaultAvatar from './defaultAvatar.vue';
export default {
    props: {
        visible: {
            type: Boolean,
        },
        msgHover: {
            type: Boolean
        }
    },
    setup(props, context) {
        const current = ref(1);
        const msgList = ref([]);
        const msgCount = ref(4);
        const loading = ref(false);
        const listRef = ref(null);
        const totalMsgCount = ref(1);
        const { x, y, isScrolling, arrivedState, directions } = useScroll(listRef, {
            offset: { top: 50, bottom: 100 }
        });
        const totalPage = ref(1);

        const isOverPage = computed(() => {
            const maxPage = Math.ceil(totalMsgCount.value / msgCount.value);
            return current.value + 1 > maxPage;
        })
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                //console.log('enter')
                current.value = 1;
                const res = await getUserNotice({
                    pageNo: current.value,
                    pageSize: msgCount.value
                });
                if (res.code === 200) {
                    msgList.value = res.data.data;
                    totalMsgCount.value = res.data.count;
                    context.root.$store.commit('setMsgCount', res.data.notreadcount);
                      nextTick(() => {
                        listRef.value && (listRef.value.scrollTop = 0);
                    })
                }
            }
        });

        onClickOutside(listRef, () => {
            context.emit('onClose')
        })

        watch(() => arrivedState, (newVal) => {
            if (arrivedState.bottom) {
                load();
            }
        }, { deep: true });
       /*  onMounted(async () => {
            if (localStorage.getItem("Authorization")) {
                const res = await getUserNotice({
                    pageNo: current.value,
                    pageSize: 10
                });
                if (res.code === 200) {
                    msgList.value = res.data.rows;
                    totalPage.value = res.data.totalPage;
                }
            }
        }); */
        const getDateFormat = (dateStr) => {
            return new dayjs(dateStr).getDateFormat("YYYY-MM-DD HH-mm-SS");
        };
        const getTypeIcon = (operatorType) => {
            // 1、项目相关，2、实验相关，3、笼位相关
            if (operatorType === 1) {
                return ProjectIcon;
            }
            else if (operatorType === 2) {
                return ExperimentIcon;
            }
            else if (operatorType === 3) {
                return CageIcon;
            }
            else if (operatorType === 5) {
                return CloudIcon;
            }
            else {
                return NotImage;
            }
        };
        // 点击的消息
        const clickedNotice = ref({});
        // 邀请对话框
        const inviteModalVisible = ref(false);
        // 正在提交邀请请求
        const isInvitionSubmitting = ref(false);
        // 消息点击事件
        const handleMsgClick = (notice) => {
            if (notice.operatorType === 1 && notice.notices.indexOf('拒绝') === -1 && notice.isOperate === 1) {
                clickedNotice.value = notice;
                inviteModalVisible.value = true;
            }
            else if(notice.operatorType === 1 && notice.notices.indexOf('拒绝') === -1 && notice.isOperate === 2) {
                message.error('消息已处理');
            }
            else if (notice.operatorType === 2) {
                const href = router.resolve({
                    name: "experiment",
                    query: {
                        mode: "preview",
                        id: notice.linkageId,
                        projectId: notice.projectId,
                    }
                });
                window.open(href.href, "_blank");
            }
            else if (notice.operatorType === 3) {
                const href = router.resolve({
                    name: "peopleCenterCageLog"
                });
                window.open(href.href, "_blank");
            }
            else if(notice.operatorType == 6) {
                const href = router.resolve({
                    name: 'researchGroup',
                    query: {
                        id: notice.linkageId
                    }
                })
                window.open(href.href, "_blank")
            }
        };
        
        const handleInviteAndAccept = async (isAgree) => {
            isInvitionSubmitting.value = true;
            const res = await joinProject({
                ProjectId: clickedNotice.value.linkageId,
                IsAgree: isAgree
            });
            if (res.code === 200 || res.code === 204) {
                message.success(res.message);
                inviteModalVisible.value = false;
            }
        };
        const refreshing = ref(false);
        const refreshNotice = async () => {
            current.value = 1;
            refreshing.value = true;
            const res = await getUserNotice({
                pageNo: current.value,
                pageSize: msgCount.value
            });
            if (res.code === 200) {
                message.success(res.message);
                msgList.value = res.data.data;
                totalMsgCount.value = res.data.count;
                listRef.value && (listRef.value.scrollTop = 0);
                context.root.$store.commit('setMsgCount', res.data.notreadcount);
            }
            refreshing.value = false;
        };
        const load = async () => {
            loading.value = true;
            if (isOverPage.value) {
                loading.value = false;
                return;
            }
            current.value = current.value + 1;
            const res = await getUserNotice({
                pageNo: current.value,
                pageSize: msgCount.value
            });
            if (res.code === 200) {
                msgList.value.splice(msgList.value.length, 0, ...res.data.data);
                context.root.$store.commit('setMsgCount', res.data.notreadcount);
            }
            loading.value = false;
        };
        return {
            current,
            msgList,
            msgCount,
            loading,
            listRef,
            arrivedState,
            NotImage,
            getTypeIcon,
            handleMsgClick,
            inviteModalVisible,
            clickedNotice,
            isInvitionSubmitting,
            handleInviteAndAccept,
            refreshNotice,
            refreshing,
            totalPage,
            isOverPage
        };
    },
    components: { DefaultAvatar }
}
</script>

<style lang="less" scoped>
    div {
        word-break: break-all;
    }
.notice {
	margin-top: 24px;
	padding-bottom: 5px;
}

.tips_box:hover {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.36);
	cursor: pointer;
	transition: .2s all ease-in-out;
}

.notice-list {
	min-height: 390px;
}
</style>

<style scoped>
div {
	line-height: normal;
    word-break: break-all;
    white-space:normal;
}

.headTips {
	width: 280px;
	height: 560px;
	overflow-y: scroll;
	border-radius: 2px;
	background-color: white;
	position: absolute;
	right: 0px;
	top: 60px;
	/* display: none; */
	z-index: 50;
	box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 12px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -3px rgba(0, 0, 0, 0.12);
    text-align: center;
}

.head {
	border-radius: 100%;
	width: 32px;
    height: 32px;
}

.tips_box {
	display: flex;
	width: 208px;
	background-color: #F8F8FA;
	margin-left: 10px;
	border-radius: 8px;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-around;
}

.tips_msg {
	border-bottom: 1px solid #EBEBEB;
	text-align: left;
	width: 190px;
	font-size: 12px;
	font-weight: normal;
	color: rgba(0, 0, 0, 0.85);
	min-height: 50px;
	margin-left: 10px;
	padding: 8px;
	/* margin: 0 5px; */
}

.refresh-msg {
	position: sticky;
	top: 0px;
	color: #1890FF;
	text-align: left;
	background: #ffffff;
	padding: 0px 10px;
	line-height: 32px;
	border-bottom: 1px solid #e4e4e4;
	z-index: 1;
}

.refresh-btn {
	cursor: pointer;
}
</style>