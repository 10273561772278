<template>
	<div class="menus">
		<a-menu v-model="current" mode="horizontal">
			<a-menu-item  @mouseover="NaVisible=true"  class="quickNavigation" key="centerProfile" >
				<router-link :to="{path:''}">
					快捷导航
				</router-link>
				<div class="quickNavigationItem" style="left: 0px;" @click="NaVisible=false" v-if="NaVisible">
					<div style="display: flex;height: 86px;justify-content: space-around;">
						<div class="Items" @click="handleAddNewExperiment">
							<img style="position: absolute;top: -24px;" src="../../assets/dx.png">
							<div style="font-size: 14px;margin-bottom: -5px;" >新建实验</div>
						</div>
						<div class="Items" @click="handleAddNewNotebookModal">
							<img style="position: absolute;top: -24px;" src="../../assets/jlb.png">
							<div style="font-size: 14px;margin-bottom: -5px;">新建记录本</div>
						</div>
						<div class="Items" @click="goToCloud">
							<img style="position: absolute;top: -24px;" src="../../assets/yp.png">
							<div style="font-size: 14px;margin-bottom: -5px;">云盘</div>
						</div>
						<div class="Items" @click="goToNewTemplate">
							<img style="position: absolute;top: -24px;" src="../../assets/mb.png">
							<div style="font-size: 14px;margin-bottom: -5px;" >新建模板</div>
						</div>
						<div class="Items" @click="goToCore">
							<img style="position: absolute;top: -24px;" src="../../assets/center.png">
							<div style="font-size: 14px;margin-bottom: -5px;">个人中心</div>
						</div>
					</div>
				</div>
			</a-menu-item>
			<a-menu-item key="workbench">
				<router-link :to="{path:'/'}">
					工作台
				</router-link>
			</a-menu-item>
			<a-menu-item key="myResearchTeam">
				<router-link :to="{ path: '/core/myResearchTeam' }">
					课题组
				</router-link>
			</a-menu-item>
			<a-menu-item key="item">
				<router-link :to="{path:'/itemCenter'}">
					项目管理
				</router-link>
			</a-menu-item>
			<a-menu-item key="experManage">
				<router-link :to="{path:'/experManage'}">
					实验记录管理
				</router-link>
			</a-menu-item>
			<!-- <a-menu-item key="cageAppoint">
				<router-link :to="{path:'/cageAppoint'}">
					笼位预约
				</router-link>
			</a-menu-item> -->
			
			
			<!-- <a-menu-item v-if="!type" @mouseover="NaVisible=true"  class="quickNavigation" key="centerProfile" >
				<router-link :to="{path:''}">
					快捷导航
				</router-link>
				<div class="quickNavigationItem" @click="NaVisible=false" v-if="NaVisible">
					<div style="display: flex;height: 86px;justify-content: space-around;">
						<div class="Items" @click="handleAddNewExperiment">
							<img style="position: absolute;top: -24px;" src="../../assets/dx.png">
							<div style="font-size: 14px;margin-bottom: -5px;" >新建实验</div>
						</div>
						<div class="Items" @click="handleAddNewNotebookModal">
							<img style="position: absolute;top: -24px;" src="../../assets/jlb.png">
							<div style="font-size: 14px;margin-bottom: -5px;">新建记录本</div>
						</div>
						<div class="Items" @click="goToCloud">
							<img style="position: absolute;top: -24px;" src="../../assets/yp.png">
							<div style="font-size: 14px;margin-bottom: -5px;">云盘</div>
						</div>
						<div class="Items" @click="goToNewTemplate">
							<img style="position: absolute;top: -24px;" src="../../assets/mb.png">
							<div style="font-size: 14px;margin-bottom: -5px;" >新建模板</div>
						</div>
						<div class="Items" @click="goToCore">
							<img style="position: absolute;top: -24px;" src="../../assets/center.png">
							<div style="font-size: 14px;margin-bottom: -5px;">个人中心</div>
						</div>
					</div>
				</div>
			</a-menu-item> -->
			
			<a-menu-item  key="bbsIndex">
				<router-link :to="{path:'/bbs'}">
					论坛
				</router-link>
			</a-menu-item>
		</a-menu>
		<AddNewExperimentModel :visible.sync="addNewExperimentModalVisible"></AddNewExperimentModel>
		<AddNewNotebookModal :visible.sync="addNewNotebookModalVisible"></AddNewNotebookModal>
	</div>
</template>

<script>
import router from '../../router';
import AddNewExperimentModel from '../experimentManage/addNewExperimentModel.vue'
import AddNewNotebookModal from '../experimentManage/addNewNotebookModal.vue';
	export default {
    name: "Menu",
	props: {
		type: {
			default: ''
		}
	},
    data() {
        return {
            current: ["workbench"],
            NaVisible: true,
            addNewExperimentModalVisible: false,
			addNewNotebookModalVisible: false
        };
    },
    watch: {
        "$route"(to, from) {
            this.initCurrent();
        }
    },
    created() {
        this.initCurrent();
    },
    methods: {
        initCurrent() {
            //let current = this.$route.path.split("/")[1];
			let current = this.$route.name;
            if (!current) {
                this.current = ["workbench"];
            }
            else {
                this.current = [current.toString()];
            }
        },
        goToCore() {
            this.$router.push({ path: "/core/basicInformation" });
        },
        goToCloud() {
            this.$router.push({ path: "/cloudDisk/own" });
        },
		goToNewTemplate() {
			const href =  this.$router.resolve({
				name: 'newExperimentTemplate'
			});
			window.open(href.href, '_blank');
		},
        handleAddNewExperiment() {
			this.addNewExperimentModalVisible = true;
        },
		handleAddNewNotebookModal() {
			this.addNewNotebookModalVisible = true;
		}
    },
    components: { AddNewExperimentModel, AddNewNotebookModal, router }
}
</script>

<style scoped>
	.Items {
		padding: 0 10px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		color: rgba(0, 0, 0, 0.85);
		min-width: 78px;
	}

	.Items:hover {
		background-color: rgba(29, 29, 29,0.2);
		color: white;
	}
	
	.Items:hover .Items div{
		color: white ;
	}
	
</style>
<style lang="less" scoped>
	.menus .ant-menu-item-selected a {
		color: @srims-primary-color !important;
		font-family: PingFang SC Bold;
		font-size: 14px !important;
		border-bottom: 2px solid @srims-primary-color !important;
		font-weight: 550 !important;

	}

	.menus .ant-menu-item-active {
		border-color: transparent !important;
	}
	.menus .ant-menu-submenu-title {
		color: @srims-primary-color !important;
	}

	.menus .ant-menu-submenu {
		top: 0 !important;
		margin: 0 1rem;

	}

	.menus .ant-menu-item {
		top: 0 !important;
		/* margin: 0 1rem; */
	}

	.menus .ant-menu-item a {
		color: rgba(0, 0, 0, 0.45);
		font-size: 14px !important;
		font-family: PingFang SC Bold;
	}

	.menus .ant-menu-submenu-selected {
		border-bottom: 2px solid white !important;
		background-color: transparent !important;
	}

	.menus .ant-menu-item-selected {
		border-bottom: 2px solid white !important;
	}

	.menus .ant-menu-horizontal {
		border-bottom: none !important;
		margin: 3px 0;
	}

	.menus .ant-menu-light {
		left: 59% !important;

	}

	.quickNavigationItem {
		z-index: 550;
		width: 462px;
		height: 86px;
		border-radius: 2px;
		background: #FFFFFF;
		position: absolute;
		top: 54px;
		display: none;
		left: -338.5px;
		box-shadow: 0px 4px 10px 0px rgba(78, 89, 105, 0.06);
	}

	.menus .quickNavigation:hover .quickNavigationItem {
		display: block !important;
	}
</style>
